<template>
  <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
    <vx-card>
      <h4 class="mb-4">ตั้งค่าระบบถอน</h4>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="mb-1">เวลาที่ลูกค้าถอนได้ต่อครั้ง</label>
          <vx-input-group class="mb-base">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " type="number" min="15" max="60" v-model="min" placeholder="ใส่จำนวนนาที" />
            <template slot="append">
              <div class="append-text bg-primary">
                <span>นาที</span>
              </div>
            </template>
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="mb-1">จำนวนพนักงานถอนเงิน </label>
          <vx-input-group class="mb-base">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " type="number" min="1" max="10" v-model="user" placeholder="ใส่จำนวนพนักงาน" />
            <template slot="append">
              <div class="append-text bg-primary">
                <span>คน</span>
              </div>
            </template>
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="mb-1">ถอนต่ำสุด</label>
          <vx-input-group class="mb-base">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " type="number" min="0" max="100000" v-model="minimum" placeholder="ใส่จำนวนบาท" />
            <template slot="append">
              <div class="append-text bg-primary">
                <span>บาท</span>
              </div>
            </template>
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="mb-1">ถอนสูงสุด</label>
          <vx-input-group class="mb-base">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " type="number" min="15" max="60" v-model="limit" placeholder="ใส่จำนวนบาท" />
            <template slot="append">
              <div class="append-text bg-primary">
                <span>บาท</span>
              </div>
            </template>
          </vx-input-group>
        </div>

        <div class="vx-col w-full">
          <vs-button :disabled="$store.state.AppActiveUser.permissions.bank.action ? false : true
              " class="mb-4" color="primary" @click="update_status_user()">ยืนยัน</vs-button>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-2">
          <h4>ตั้งค่าช่องทางการถอน</h4>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-3">
              <p v-if="scb_bank.length > 0" style="color:white;">
                ถอน SCB ผ่านเว็บ
              </p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="scb_bank.length > 0" v-model="scb_bank[0].bank_status_web"
                @click="update_bankshow(scb_bank[0].bank_status_web, 'SCB_WEB')">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-3">
              <p v-if="scb_bank.length > 0" style="color:white;">
                ถอน SCB ผ่านแอพ
              </p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="scb_bank.length > 0" v-model="scb_bank[0].bank_status_app"
                @click="update_bankshow(scb_bank[0].bank_status_app, 'SCB_APP')">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-4">
              <p v-if="kbank_bank.length > 0" style="color:white;">
                ถอน KBANK ผ่านเว็บ
              </p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-3">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="kbank_bank.length > 0" v-model="kbank_bank[0].bank_status_web" @click="
              update_bankshow(kbank_bank[0].bank_status_web, 'KBANK_WEB')
              ">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-3">
              <p v-if="kbank_bank.length > 0" style="color:white;">
                ถอน KBANK ผ่านแอพ
              </p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-3">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="kbank_bank.length > 0" v-model="kbank_bank[0].bank_status_app" @click="
              update_bankshow(kbank_bank[0].bank_status_app, 'KBANK_APP')
              ">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-3">
              <p v-if="ktb_bank.length > 0" style="color:white;">ถอน KTB</p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-3">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="ktb_bank.length > 0" v-model="ktb_bank[0].bank_status"
                @click="update_bankshow(ktb_bank[0].bank_status, 'KTB')">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-3">
              <p v-if="bay_bank.length > 0" style="color:white;">ถอน BAY</p>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-2">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action
              ? false
              : true
              " v-if="bay_bank.length > 0" v-model="bay_bank[0].bank_status"
                @click="update_bankshow(bay_bank[0].bank_status, 'BAY')">
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <vx-card class="my-5">
      <h4 class="mb-4">ตั้งค่าระบบถอน Payment</h4>
      <vs-table search stripe :data="withdrawSettings">

        <template slot="thead">
          <vs-th>name</vs-th>
          <vs-th>setting</vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.setting_name">
              {{ tr.setting_name }}
            </vs-td>
            <vs-td :data="tr.setting_status">
              <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"
                v-model="tr.setting_status"
                @click="update_status(tr.setting_no, tr.setting_status, tr.setting_name, 'Payment')">
                <span slot="on">เปิด</span>
                <span slot="off">ปิด</span>
              </vs-switch>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../axios'

const dict = {
  user: {
    username: {
      max_value: 'พนักงานถอนห้ามเกิน 10 คน',
      min_value: 'พนักงานถอนห้ามน้อยกว่า 4 คน'
    },
    time: {
      max_value: 'เวลาในการถอนห้ามเกิน 60 นาที',
      min_value: 'เวลาในการถอนห้ามน้อยกว่า 15 นาที'
    }
  }
}
import { Validator } from 'vee-validate'
Validator.localize('en', dict)
export default {
  data () {
    return {
      scb_bank: [],
      ktb_bank: [],
      bay_bank: [],
      kbank_bank: [],
      datawithdraw: [],
      user: '',
      min: '',
      user_status: '',
      user_min: '',
      status: {},
      bf_user: '',
      bf_min: '',
      log_info: '',
      withdrawSettings: [],
      limit: '',
      minimum: '',
      bf_limit: '',
      bf_minimum: ''
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  async mounted () {
    await axios
      .get('settingwithdraw/settingwithdraw')
      .then(response => (this.datawithdraw = response.data))
    this.user = this.datawithdraw[0].setting_data
    this.min = this.datawithdraw[1].setting_data
    this.limit = this.datawithdraw[4].setting_data
    this.minimum = this.datawithdraw[5].setting_data
    this.bf_user = this.datawithdraw[0].setting_data
    this.bf_min = this.datawithdraw[1].setting_data
    this.bf_limit = this.datawithdraw[4].setting_data
    this.bf_minimum = this.datawithdraw[5].setting_data


    await axios
      .get('settingwallet/banktransfer/scb')
      .then(response => (this.scb_bank = response.data))
    await axios
      .get('settingwallet/banktransfer/ktb')
      .then(response => (this.ktb_bank = response.data))
    await axios
      .get('settingwallet/banktransfer/bay')
      .then(response => (this.bay_bank = response.data))
    await axios
      .get('settingwallet/banktransfer/kbank')
      .then(response => (this.kbank_bank = response.data))
    await axios
      .get('settingsystem/settingsystem/withdraw')
      .then(response => (this.withdrawSettings = response.data))
  },
  methods: {
    async update_bankshow (bank_show, bankname) {
      if (bank_show === 1 || bank_show === true) {
        bank_show = false
      } else {
        bank_show = true
      }
      await axios
        .post('withdraw/updatestatus', {
          setting: bankname,
          show: bank_show
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_show === true ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_show === true ? 'check_box' : 'error',
          title:
            bank_show === true ? `เปิดใข้งานระบบถอน : ${bankname} สำเร็จ` : `ปิดใข้งานระบบถอน : ${bankname} สำเร็จ`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title:
            bank_show === true ? `เปิดใข้งานระบบถอน : ${bankname} ไม่สำเร็จ!!` : `ปิดใข้งานระบบถอน : ${bankname} ไม่สำเร็จ!!`,
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_status_user () {
      if (this.user <= 10 && this.user >= 1) {
        if (this.min <= 60 && this.min >= 15) {
          await axios
            .post('settingwithdraw/withdrawstatus', {
              user_code: 'num',
              min_code: 'iinterval',
              limit_code: 'limit',
              minimum_code: 'minimum',
              user_data: this.user,
              min_data: this.min,
              limit_data: this.limit,
              minimum_data: this.minimum

            })
            .then(response => (this.status = response.data))
          if (this.status.status === true) {
            this.$vs.notify({
              time: 8000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: 'ทำรายการสําเร็จ',
              text: `จำนวนพนักงานถอน : ${this.user} คน | หน่วงเวลาถอน : ${this.min} นาที | ถอนต่ำสุด : ${this.minimum} บาท | ถอนสูงสุด : ${this.limit} บาท`
            })
            if (this.user !== this.bf_user && this.min === this.bf_min) {
              this.log_info = `จำนวนพนักงานถอน : ${this.bf_user} > ${this.user} คน`
            } else if (this.min !== this.bf_min && this.user === this.bf_user) {
              this.log_info = `หน่วงเวลาถอน : ${this.bf_min} > ${this.min} นาที`
            } else if (this.user !== this.bf_user && this.min !== this.bf_min) {
              this.log_info = `จำนวนพนักงานถอน : ${this.bf_user} > ${this.user} คน  หน่วงเวลาถอน : ${this.bf_min}>${this.min} นาที`
            }
            this.refresh_data()
          } else {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'ไม่สามารถทำรายการได้',
              text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
            })
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่สามารถทำรายการได้',
            text: 'จำนวนเวลาถอน มากสุด 60 นาทีน้อยสุด 15 นาที'
          })
        }
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ไม่สามารถทำรายการได้',
          text: 'จำนวนพนักงานถอน มากสุด 10 คนน้อยสุด 1 คน'
        })
      }
    },
    async refresh_data () {
      await axios
        .get('settingwithdraw/settingwithdraw')
        .then(response => (this.datawithdraw = response.data))
      this.user = this.datawithdraw[0].setting_data
      this.min = this.datawithdraw[1].setting_data
      this.bf_user = this.datawithdraw[0].setting_data
      this.bf_min = this.datawithdraw[1].setting_data
    },
    async update_status (setting_no, setting_status, setting_name, system_name) {
      // console.log('setting_no', setting_no, 'setting_status', setting_status, 'setting_name', setting_name)
      if (setting_status === true) {
        setting_status = false
      } else {
        setting_status = true
      }
      await axios
        .post('settingsystem/systemstatus', {
          no: setting_no,
          status: setting_status,
          name: setting_name,
          bonus: system_name
        }).then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: setting_status === true ? 'success' : 'warning',
          position: 'top-right',
          icon: setting_status === true ? 'check_box' : 'error',
          title: setting_status === true ? 'เปิด' : 'ปิด',
          text: setting_status === true ? `ระบบ : ${setting_name}` : `ระบบ : ${setting_name}`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.onoff_status.status === false ? 'เปิดระบบ หน้าเว็บไม่สำเร็จ!!' : 'ปิดระบบ หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
        if (setting_status === true) {
          setting_status = true
        } else if (setting_status === false) {
          setting_status = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
